import {onMounted, onUnmounted, ref} from "vue";

export function useSize(){
    const width = ref(0)
    const height = ref(0);
    const resize = () => {
        width.value = window.innerWidth;
        height.value = window.innerHeight;
    }
    onMounted(() => {
        resize()
        window.addEventListener('resize', resize)
    })
    onUnmounted(() => window.removeEventListener('resize', () => {
        resize()
        window.removeEventListener('resize', resize)
    }))
    return {width, height}
}