<template>
  <lay-layout v-user-no-select  style="width: 100%;"  v-if="true">
    <lay-header style="height:80px;z-index: 999999999;">
      <TopBar style="position: fixed;top: 0px;left: 0px"></TopBar>
    </lay-header>
    <lay-body  style="margin-top: -80px;overflow: hidden">
      <img src="images/banner.png" style="width: 100%;height: auto;min-height: 150px;z-index: -999">
      <router-view></router-view>
    </lay-body>
    <FooterView></FooterView>
  </lay-layout>
  <lay-backtop  :showHeight="0" :bottom="20"  :right="20" iconSize="38" borderRadius="50%" bgcolor="#00b4ff" :style="{'opacity': opacity}" v-if="false"></lay-backtop>

  <lay-container v-if="false">
    <lay-row style="margin-top: 200px">
      <lay-col md="9" mdOffset="6">
        <lay-exception status="403" title="403" describe="暂无相关权限"></lay-exception>
      </lay-col>
    </lay-row>
  </lay-container>

</template>
<script setup>
import TopBar from "@/components/TopBar";
import {ref, computed, provide, readonly} from "vue";
import {useClock} from "@/assets/js/clock";
import {useSize} from "@/assets/js/size";
import FooterView from "@/components/utils/FooterView";

const opacity = ref(0);



window.addEventListener('scroll', function() {
  const last_known_scroll_position = window.scrollY;
  if (last_known_scroll_position < 200){
    opacity.value = 0;
  }
  else {
    if ( opacity.value == 0){
      opacity.value = 1;
    }
  }
});




const {seconds} = useClock();
const activePage = ref(0)
const intrActivePage = ref(0);
const changeActivePage = (id) => {
  activePage.value = id;
  window.scrollTo(0,0)
}
const changeIntrActivePage = (id) => {
  intrActivePage.value = id;
}
provide("activePage", readonly(activePage));
provide("changeActivePage", changeActivePage);

provide("intrActivePage", readonly(intrActivePage));
provide("changeIntrActivePage", changeIntrActivePage);

provide("seconds", seconds);


const {width} = useSize()

const isXs = computed(() => width.value < 768);
const isSm = computed(() => width.value >= 768);
const isMd = computed(() => width.value >= 992);
const isLg = computed(() => width.value >= 1200);
const isXl = computed(() => width.value >= 1500);

provide("isXs", readonly(isXs))
provide("isSm", readonly(isSm))
provide("isMd", readonly(isMd))
provide("isLg", readonly(isLg))
provide("isXl", readonly(isXl))



window.addEventListener('popstate', () => {
  locatePath()
})

const locatePath = function (){
  let path = window.location.pathname
  let routes = [
    { path: '/' },
    { path: '/intr'},
    { path: '/case'},
    { path: '/honor'},
    { path: '/contact'},
  ]
  for (let i = 0; i <  routes.length ; i++){
    let item = routes[i];
    if (item.path == path || (item.path == "/" &&  path == "")){
      activePage.value = i;
      break;
    }
  }
}
locatePath()



</script>

<style>

#app {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.layui-tab-brief>.layui-tab-head>.layui-tab-title > .layui-this{
  color: #1e9fff !important;
}
.layui-tab-active-bar{
  background-color: #1e9fff !important;
}
.layui-body{
  min-height: 0 !important;
}
.layui-footer{
  line-height: 40px;
}
.layui-tab-content{
  display: none;
}



</style>
