import {computed, onMounted, onUnmounted, ref} from "vue";

export function useClock(){

    let timerId = null;
    const time = ref(new Date())

    const format = (value) => {
        return value < 10 ? '0' + value : value;
    };

    const seconds = computed(() => {
        let tmp = time.value;
        let yy = tmp.getFullYear();
        let MM = format(tmp.getMonth() + 1);
        let dd = format(tmp.getDate());
        let hh = format(tmp.getHours());
        let mm = format (tmp.getMinutes());
        let ss = format(tmp.getSeconds());
        return yy + "-" + MM + "-" + dd + " " + hh + ":" + mm + ":" + ss;
    });

    onMounted(() => {
        timerId = setInterval(() => {
            time.value = new Date();
        }, 1000);
    })

    onUnmounted(() => {
        clearInterval(timerId);
    })

    return {seconds}
}