<template>
  <lay-col sm="24" style="background-color: #101010;color: #fefefe;">
    <lay-col sm="16" sm-offset="4">
      <lay-panel style="margin: 15px 0 20px;background-color: #101010;border: none;padding-bottom: 0" shadow="never">
        <h3 >
          <lay-breadcrumb separator="|"  id="layui-breadcrumb-custom">
            <lay-breadcrumb-item>
              <router-link to="/intr" @click="changeActivePage(1);changeIntrActivePage(0)"  :style="isSm ? {} : {'font-size': '15px'}">走进升平</router-link>
            </lay-breadcrumb-item>
            <lay-breadcrumb-item>
              <router-link to="/case" @click="changeActivePage(2)" :style="isSm ? {} : {'font-size': '15px'}" >工程案例</router-link>
            </lay-breadcrumb-item>
            <lay-breadcrumb-item >
              <router-link to="/honor" @click="changeActivePage(3)" :style="isSm ? {} : {'font-size': '15px'}" >荣誉资质</router-link>
            </lay-breadcrumb-item>
            <lay-breadcrumb-item style="color: #666">
              <router-link to="/contact" @click="changeActivePage(4)" :style="isSm ? {} : {'font-size': '15px'}" >联系我们</router-link>
            </lay-breadcrumb-item>
          </lay-breadcrumb>
        </h3>
        <lay-row>
          <lay-col md="12" sm="24" >
            <p  class="info" :class="isSm ? '': 'sm'">
              <a href="tel:0532-87967898" style="text-decoration: none;color: #FFF">联系电话：0532-87967898</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </p>
            <p  class="info" :class="isSm ? '': 'sm'">
              <span>联系邮箱：shengpingdianqi@163.com</span>
            </p>
            <p  class="info" :class="isSm ? '': 'sm'">
              <span>邮政编码：266109</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </p>
            <p  class="info" :class="isSm ? '': 'sm'">
              <span>传真：0532-87712111</span>
            </p>
            <p  class="info" :class="isSm ? '': 'sm'">
              <span>联系地址：青岛市城阳区城阳街道古庙头村工业园</span>
            </p>
            <div class="info info-pad" :class="isSm ? '': 'sm'">
              <a href="http://beian.miit.gov.cn/" target="_blank" style="color: #fefefe">青岛升平电气有限公司备案/许可证编号：鲁ICP备18004831号</a>
            </div>
            <p >&nbsp;</p>
          </lay-col>
          <lay-col md="12" sm="24" >
            <p style="width: 145px;text-align: center;line-height: 30px;margin: 0px auto;" >
              <img src="images/erweima.png" style="height: 129px; width: 129px;border: 1px solid #fefefe;padding: 7px">
              <span>升平电气微信公众号</span>
            </p>
          </lay-col>
        </lay-row>
      </lay-panel>
    </lay-col>
  </lay-col>
</template>
<script setup>
import {inject} from "vue";
const changeActivePage = inject("changeActivePage");
const changeIntrActivePage = inject("changeIntrActivePage");
const isSm = inject("isSm")
</script>

<style>
.layui-breadcrumb > *{
  font-size: 18px !important;
}
.layui-breadcrumb a:nth-last-child(2), .layui-breadcrumb a{
  color: #fefefe !important;
}

#layui-breadcrumb-custom a:hover{
  color: #00b4ff !important;
}
</style>

<style scoped>
.info{
  line-height: 30px;
  font-size: 15px;
}
.info-pad{
  letter-spacing: 1px;
}
.sm{
  font-size: 13px;
}
</style>