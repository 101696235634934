<template>
  <lay-row>
    <PageNav name="荣誉资质" v-if="false"/>
    <lay-col sm="24" style="background-color: #efeee8;text-align: center" >
        <h3 style="color: #0074ea;font-size: 30px;height: 90px;line-height: 90px">荣誉资质</h3>
        <p>
          <img src="images/06.jpg" class="honor-img" >
          <img src="images/04.jpeg" class="honor-img" :style="isSm ? {} : {'width' : '90%', 'height' : 'auto', 'margin': '0 auto'}">
        </p>
      <p style="margin-top: 5px;margin-bottom: 30px">
        <img src="images/01.jpeg" class="honor-img">
        <img src="images/02.jpeg" class="honor-img">
        <img src="images/03.jpeg" class="honor-img">
        <img src="images/05.jpeg" class="honor-img">
      </p>
    </lay-col>
    <lay-col :sm="isSm ? 16 : 24" :sm-offset="isSm ? 4 : 0" style="text-align: center;padding: 35px 0">
      <img src="images/timeline.png?v=20231205" style="height: auto;width: 80%" >
    </lay-col>
  </lay-row>
</template>
<script setup>

import PageNav from "@/components/utils/PageNav";
import {inject} from "vue";
const isSm = inject('isSm')
</script>

<style scoped>
.honor-img{
  height: 350px;
  width: auto;
  margin-left: 10px;
}
</style>