<template>
  <Lay-row>
    <PageNav name="联系我们" v-if="false"/>
    <lay-col sm="16" sm-offset="4" >
      <p>
        <img src="images/lianxi.png?v=20231205" class="img" alt="">
      </p>
      <p style="padding-bottom: 20px">
        <lay-field  title='青岛升平电气有限公司'  >
          <a href="https://map.baidu.com/poi/%E9%9D%92%E5%B2%9B%E5%8D%87%E5%B9%B3%E7%94%B5%E6%B0%94%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8/@13402285.49,4320743.5,18.46z?uid=e2c734e44e01105c14a1909b&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl" target="_blank" >
            <img src="images/map.png" class="img" >
          </a>
        </lay-field>
      </p>
      <p style="padding-bottom: 5px" >
        <lay-field title="青岛升平电气有限公司莱西分公司"  >
          <a href="https://map.baidu.com/poi/%E9%9D%92%E5%B2%9B%E5%8D%87%E5%B9%B3%E7%94%B5%E6%B0%94%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8%E8%8E%B1%E8%A5%BF%E5%88%86%E5%85%AC%E5%8F%B8/@13422435.228120252,4364337.19750567,16.17z?uid=5b92ee39013e104f240bd030&info_merge=1&isBizPoi=false&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl" target="_blank" >
            <img src="images/map_laixi.png?v=20231205" class="img" >
          </a>
        </lay-field>
      </p>
    </lay-col>
  </Lay-row>
</template>
<script setup>
import PageNav from "@/components/utils/PageNav";
import {inject, onMounted, watch} from "vue";
const isMd = inject('isMd')
const fontSizeChange = function (newIsMd){
  let fontSize = '20px';
  if (!newIsMd){
    fontSize = '15px';
  }
  let legends = document.querySelectorAll('legend');
  legends.forEach(item => {
    item.style.fontSize = fontSize
  })
}
onMounted(() => {
  fontSizeChange(isMd.value);
})
watch(isMd , (newIsMd) => {
  fontSizeChange(newIsMd)
})
</script>

<style scoped>
.img{
  width: 100%;height: auto;
}
</style>