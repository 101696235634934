<template>
  <lay-row >
    <PageNav name="工程案例" v-if="false"/>
    <lay-col sm="16" sm-offset="4">
      <SignBoard title="母线工程" sub-title="closed busbar"></SignBoard>
    </lay-col>
    <lay-col sm="24"><div style="height: 15px"></div></lay-col>
    <lay-col sm="16" sm-offset="4">
      <lay-row :space="isSm ? 10 : 0" style="text-align: center">
        <lay-col md="8" sm="12">
          <img src="images/muxian1.png" class="project-img" alt="">
        </lay-col>
        <lay-col md="8" sm="12">
          <img src="images/muxian2.png" class="project-img" alt="">
        </lay-col>
        <lay-col md="8" sm="12">
          <img src="images/muxian3.png" class="project-img" alt="">
        </lay-col>
        <lay-col md="8" sm="12">
          <img src="images/shouchuang.png?v=20231122" class="project-img" alt="">
        </lay-col>
        <lay-col md="8" sm="12">
          <img src="images/aoyuan.png" class="project-img" alt="">
        </lay-col>
        <lay-col md="8" sm="12">
          <img src="images/baoli.png" class="project-img" alt="">
        </lay-col>
      </lay-row>
    </lay-col>
    <lay-col sm="16" sm-offset="4">
      <SignBoard title="桥架工程" sub-title="bridge"></SignBoard>
    </lay-col>
    <lay-col sm="24"><div style="height: 15px"></div></lay-col>
    <lay-col sm="16" sm-offset="4">
      <lay-row :space="isSm ? 10 : 0" style="text-align: center">
        <lay-col md="8" sm="12">
          <img src="images/qiaojia1.png" class="project-img" alt="">
        </lay-col>
        <lay-col md="8" sm="12">
          <img src="images/qiaojia2.png" class="project-img" alt="">
        </lay-col>
        <lay-col md="8" sm="12">
          <img src="images/qiaojia3.png" class="project-img" alt="">
        </lay-col>
        <lay-col md="8" sm="12">
          <img src="images/qiaojia4.png" class="project-img" alt="">
        </lay-col>
        <lay-col md="8" sm="12">
          <img src="images/qiaojia5.png" class="project-img" alt="">
        </lay-col>
        <lay-col md="8" sm="12">
          <img src="images/qiaojia6.png" class="project-img" alt="">
        </lay-col>
      </lay-row>
    </lay-col>
    <lay-col sm="16" sm-offset="4">
      <SignBoard title="送变电工程" sub-title="power transmission"></SignBoard>
    </lay-col>
    <lay-col sm="24"><div style="height: 15px"></div></lay-col>
    <lay-col sm="16" sm-offset="4">
      <lay-row :space="isSm ? 10 : 0" style="text-align: center">
        <lay-col md="8" sm="12">
          <img src="images/qiaojia6.png" class="project-img" alt="">
        </lay-col>
        <lay-col md="8" sm="12">
          <img src="images/songbiandian1.png?v=202312081435" class="project-img" alt="">
        </lay-col>
        <lay-col md="8" sm="12">
          <img src="images/songbiandian2.png" class="project-img" alt="">
        </lay-col>
        <lay-col md="8" sm="12">
          <img src="images/songbiandian3.png" class="project-img" alt="">
        </lay-col>
        <lay-col md="8" sm="12">
          <img src="images/zhaoshang.png" class="project-img" alt="">
        </lay-col>
        <lay-col md="8" sm="12">
          <img src="images/songbiandian5.png" class="project-img" alt="">
        </lay-col>
        <lay-col md="8" sm="12">
          <img src="images/shouchuang.png?v=20231122" class="project-img" alt="">
        </lay-col>
        <lay-col md="8" sm="12">
          <img src="images/aoyuan.png" class="project-img" alt="">
        </lay-col>
        <lay-col md="8" sm="12">
          <img src="images/baoli.png" class="project-img" alt="">
        </lay-col>
      </lay-row>
    </lay-col>
  </lay-row>
</template>

<script setup>

import PageNav from "@/components/utils/PageNav";
import SignBoard from "@/components/utils/SignBoard";
import {inject} from "vue";
const isSm = inject("isSm")
</script>

<style scoped>
.project-img{
  width: 90%;
  height: auto;
  min-height: 275px;
}
</style>