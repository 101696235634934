<template>
  <div style="height: 80px; line-height: 80px;  color: #FFF;font-size: 16px;letter-spacing: 2px;overflow: hidden;width: 100%" :style="isSm ? {} : {'height': '50px', 'line-height': '50px'}">
    <lay-row >
      <lay-col sm="24"  style="background-color: rgba(0,0,0,0.6);">
        <lay-col :sm="isSm ? 16 : 22" :sm-offset="isSm ? 4 : 1">
          <router-link to="/" class="index" :class="activeIdx == 0 ? 'active' : ''" @click="indexClick(0)" >
            <img src="../assets/img/logo.png" style="width: auto; height: 64px;cursor: pointer" alt=""  :style="isSm ? {} : {'height': '30px'}">
          </router-link>
          <lay-space :size="0" style="float: right;" :style="isSm ? {} : {'font-size' : '12px'}">
            <router-link to="/" class="index indicator" :class="activeIdx == 0 ? 'active' : ''" @click="indexClick(0)" >网站首页</router-link>
            <div :style="isSm ? {'width': '20px'} : {'width': '6px'}"></div>
            <router-link to="/intr" class="index indicator" :class="activeIdx == 1 ? 'active' : ''" @click="indexClick(1);changeIntrActivePage(0)"  >走进升平</router-link>
            <div :style="isSm ? {'width': '20px'} : {'width': '6px'}"></div>
            <router-link to="/case" class="index indicator" :class="activeIdx == 2 ? 'active' : ''" @click="indexClick(2)" >工程案例</router-link>
            <div :style="isSm ? {'width': '20px'} : {'width': '6px'}"></div>
            <router-link to="/honor" class="index indicator" :class="activeIdx == 3 ? 'active' : ''" @click="indexClick(3)" >荣誉资质</router-link>
            <div :style="isSm ? {'width': '20px'} : {'width': '6px'}"></div>
            <router-link to="/contact" class="index indicator" :class="activeIdx == 4 ? 'active' : ''" @click="indexClick(4)" >联系我们</router-link>
          </lay-space>
        </lay-col>
      </lay-col>
    </lay-row>
  </div>
</template>

<script setup>
import {defineProps, inject} from "vue";
defineProps(['title'])
const activeIdx = inject("activePage")
const changeActivePage = inject("changeActivePage")
const changeIntrActivePage = inject("changeIntrActivePage");
const indexClick = (id) => {
  changeActivePage(id);

}
const isSm = inject("isSm")

</script>

<style scoped>
.index{
  color: #FFF;
  cursor: pointer;
}

.index:hover, .index.indicator.active{
  color:  #00b4ff;
}

.index.indicator:after{
  border-bottom: 5px solid #00b4ff;
  width: 0px;
  content: "";
  display: block;
  margin-top: -5px;
  transition: width 0.5s;
  -moz-transition: width 0.5s;
  -webkit-transition: width 0.5s;
  -o-transition: width 0.5s;
}

.index.indicator.active:after,.index.indicator:hover:after{
  width: 100%;
}
</style>