<template>
  <lay-row >
    <lay-col sm="16" sm-offset="4">
      <SignBoard title="产业链条" sub-title="industry"></SignBoard>
    </lay-col>
    <lay-col sm="16" sm-offset="4">
      <router-link to="/intr" class="index"   @click="changeActivePage(1);changeIntrActivePage(3)"  >
        <img src="images/chanpin.png" style="height: auto; width: 100%; cursor: pointer">
      </router-link>
    </lay-col>
    <lay-row>
      <lay-col sm="24" >
        <div class="company" :class="isMd ? 'detail' : ''" >
          <lay-col sm="16" sm-offset="4">
            <lay-panel style="margin-top: 15px;height:260px;border: none;padding: 10px" shadow="never" :style="isMd ? {} : {'height': '520px'}">
              <lay-col md="12" sm="24" >
                <img src="images/sp.png"  class="detail-img">
              </lay-col>
              <lay-col md="12" sm="24" >
                <img src="images/3.png" class="detail-img">
                <p style="margin-top: -180px;padding: 10px 30px;color: #FFF;letter-spacing: 1px;font-size: 14px" :style="isMd ? {} : {'padding': '10px 20px','font-size' : '13px', 'letter-spacing': '1px'}">青岛升平电气有限公司是青岛市定点生产、制造高低压开关成套设备的骨干企业。公司位于青岛城阳北部工业园，东临青银、青烟等高级公路，西依济青高速公路和胶济铁路，交通便利。
                  <br/>
                  <router-link to="/intr" c   @click="changeActivePage(1);changeIntrActivePage(0)"  style="border: 1px solid #FFF;text-decoration: none;color: #FFF;padding: 3px 10px;font-size: 12px;margin-top: 10px;display: inline-block">
                      查看更多>
                  </router-link>
                </p>
              </lay-col>
            </lay-panel>
          </lay-col>
        </div>
      </lay-col>
    </lay-row>
    <lay-row>
      <div style="height: 10px"></div>
    </lay-row>
    <lay-col sm="16" sm-offset="4" >
      <SignBoard title="合作项目" sub-title="service"></SignBoard>
      <lay-row :space="isMd ? 5 : 0">
        <lay-col sm="24" >
          <img src="images/cooperation.png" class="service-img">
        </lay-col>
      </lay-row>
    </lay-col>
    <lay-col sm="24" style="background-color: #e5e5e5;margin-top: 20px;text-align: center">
      <lay-col sm="18" sm-offset="3">
        <lay-panel style="margin-top: 15px;background-color: #e5e5e5;border: none;padding: 0" shadow="never">
          <h2 style="color: #012a6f;font-size: 30px;height: 50px;line-height: 50px;">品牌合作</h2>
          <hr style="background-color: #15ce02;height: 3px;width: 40px;margin: 0 auto">
          <lay-row>
            <lay-col sm="24" >
              <p style="padding-top: 8px;text-align: center">
                <img src="../assets/img/dianwang.png" class="brand">
                <img src="../assets/img/lvcheng.png" class="brand">
                <img src="images/zhongkexin.png" class="brand">
                <img src="../assets/img/tianyi.png" class="brand">
<!--                <img src="../assets/img/huaren.png" class="brand">-->
                <img src="../assets/img/jiazhaoye.png" class="brand">
                <img src="../assets/img/zhuoyue.png" class="brand">
                <img src="../assets/img/hongxing.png" class="brand">
                <img src="../assets/img/weidong.png" class="brand">
                <img src="../assets/img/shouchuanglogo.png" class="brand">
                <img src="../assets/img/haier.png" class="brand">
                <img src="../assets/img/bl.jpeg" class="brand">
                <img src="../assets/img/heda.png" class="brand">
                <img src="../assets/img/yurun.png" class="brand">
                <img src="../assets/img/jinmao.png" class="brand">
                <img src="../assets/img/rongchuang.png" class="brand">
                <img src="../assets/img/zhaoshang.png" class="brand">
<!--                <img src="../assets/img/jiazhaoye.png" class="brand">-->
              </p>
            </lay-col>
          </lay-row>
        </lay-panel>

      </lay-col>
    </lay-col>
  </lay-row>
</template>

<script setup>
import SignBoard from "@/components/utils/SignBoard";
import {inject} from "vue";
const changeActivePage = inject("changeActivePage")
const changeIntrActivePage = inject("changeIntrActivePage")
// const isSm = inject('isSm')
const isMd = inject('isMd')
</script>
<style scoped>
.company.detail{
  background: url('../assets/img/1.png') no-repeat center/100% 320px;
  height: 320px
}
.brand{
  height: 80px;
  width: 230px;
  padding: 5px;
}
.detail-img{
  height: 260px;
  width: 100%;
}
.service-img{
  height: auto;
  width: 100%;
  margin-bottom: 5px;
}
</style>