<template>
  <lay-row>
    <lay-col  sm="16" sm-offset="4" >
      <lay-col sm="8"  xs="8" v-if="false">
        <p style="width: 100%;height: 45px;line-height: 45px;font-size: 15px;letter-spacing: 1px;visibility: hidden" :style="isMd ? {} : {'font-size': '13px'}">
          <img src="../../assets/img/shouye.png" style="width: 21px;height: 21px;vertical-align: sub;" >&nbsp;
          <router-link to="/" style="color: #333;cursor: pointer"  @click="changeActivePage(0)" >首页</router-link>
          <span>&nbsp;>&nbsp;</span><span style="color: #0074ea">{{name}}</span>
        </p>
      </lay-col>
      <lay-col  sm="24"   xs="24">
        <p style="height: 45px;line-height: 45px;font-size: 15px;letter-spacing: 1px;text-align: center" :style="isMd ? {} : {'font-size': '13px'}">
          <slot></slot>
        </p>
      </lay-col>
    </lay-col>
    <hr style="background-color: #cdcdcd;margin: 0"/>
  </lay-row>
</template>

<script setup>
import {defineProps, inject} from "vue";
defineProps(['name'])
const changeActivePage = inject("changeActivePage")
const isMd = inject('isMd')
</script>

<style scoped>

</style>