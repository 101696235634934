<template>
  <lay-row>
    <PageNav name="走进升平" >
      <lay-space size="0">
        <a href="javascript:void(0)" class="intr" :class="intrActivePage == 0 ? 'active' : ''" @click="changeIntrActivePage(0)">升平概况</a>
        <div :style="isMd ? {'width': '45px'} : {'width': '6px'}"></div>
        <a href="javascript:void(0)" class="intr" :class="intrActivePage == 1 ? 'active' : ''" @click="changeIntrActivePage(1)">合作单位</a>
        <div :style="isMd ? {'width': '45px'} : {'width': '6px'}"></div>
        <a href="javascript:void(0)" class="intr" :class="intrActivePage == 2 ? 'active' : ''" @click="changeIntrActivePage(2)">升平产品</a>
        <div :style="isMd ? {'width': '45px'} : {'width': '6px'}"></div>
        <a href="javascript:void(0)" class="intr" :class="intrActivePage == 3 ? 'active' : ''" @click="changeIntrActivePage(3)">产业链条</a>
      </lay-space>
    </PageNav>
  </lay-row>
  <lay-row v-show="intrActivePage == 0">
    <lay-col sm="16" sm-offset="4">
      <h3 style="text-align: center;font-size: 30px;height: 60px; line-height: 60px">企业简介</h3>
      <div style="text-align: left">
        <div style="float:left;margin-right: 50px;text-align: center" :style="isMd ? {} : {'margin-right' : '10px'}" >
            <img src="images/text_one.png"  style="height: auto;" :style="isXs ? {'height' :  width  - 20  + 'px' , 'width': width  - 20  + 'px'} : {'width': width / 3 + 'px'}" alt=""  />
        </div>
        <div style="margin-left: 20px;padding: 20px;line-height: 30px;text-indent: 2rem;" :style="isMd ? {} : {'margin-left': '0'}">
          <p >&nbsp;</p>
          <p>
            <span>
              青岛升平电气有限公司总部位于青岛市莱西市店埠镇兴店路87-2号，西依沈海高速公路、东依龙青高速，国道G204（青烟一级公路）和青荣城际铁路，距青岛胶东国际机场70公里，交通便利。公司占地面积10万平方米，注册资金10521万元，资产总额1.77亿元。现有员工126人，其中专业工程技术及管理人员共51人。
            </span>
          </p>
          <p style="margin-top: 15px">
            青岛升平电气有限公司是集多种封闭母线槽，各类高、低压开关柜、JP柜，配电箱，箱式变电站及各种电工铜、铝棒，铜、铝母线，铜铝复材等各种电气设备、配件及施工于一体的综合性企业。具有山东省住房和城乡建设厅颁发的安全生产许可证，并获得了电力工程施工总承包贰级、输变电工程专业承包贰级资质，承装、承修、承试电力设施三级资质，青岛市入网作业安全资质，可承担单项金额不超过企业注册资本金5倍的220kV及以下送电线路（含电缆工程）和同电压等级变电站工程的施工。具备生产高低压成套开关设备和控制设备的资质，其中SPJZ、SPMX1、SPMX2、CKX高强封闭母线槽实现了从设计、生产、制作、检测、安装及售后服务的一条龙服务，保证了高质量产品的安全性、可靠性；高压成套设备主要有：高压母线槽、预装式变电站、KYN28中置式开关柜和KYN61户内高压交流金属封闭开关设备。低压成套设备主要有：SLVA、MNS、GCK、GCS、GGD、SPJ、SDY、XL-21、XGM、CDCs系列电缆分线箱、JP柜，各种电缆桥架等。高压成套设备国家认可的型式试验报告齐全，低压成套设备系列产品均已取得中国质量认证中心CQC证书。
          </p>
          <p style="margin-top: 15px">
            依靠科技求发展，不断为用户提供满意的高科技产品，是我们始终不变的追求。公司建立了完善的质量保证体系，通过了ISO9001质量管理体系认证、ISO14001环境管理体系认证及OHSAS18001职业健康安全管理体系，实行了从设计、采购、生产、检验、销售及售后服务层层把关的管理制度，以一流的产品质量和精湛的技术服务受到了用户的一致好评。
          </p>
          <p style="margin-top: 15px">
            公司在国家电网、房地产、商业、工业等各行业领域输变电工程项目具备丰富的承包经验和施工能力，履约能力优秀，取得了AAA级信用企业证书，社会信誉高。
          </p>
          <p style="margin-top: 15px">
            本着“处处让顾客满意，时时对品牌负责”的宗旨及“质量就是企业的生命，诚信是企业的根本”的经营理念，愿与社会各界朋友真诚合作，共展宏图！
          </p>
        </div>
      </div>
    </lay-col>
    <lay-col sm="16" sm-offset="4">
      <div style="text-align: left">
        <h4 style="height: 30px; line-height: 30px;font-weight: bolder;margin-left: 20px">运维试验项目部简介</h4>
        <div style="margin-left: 20px;padding: 20px;line-height: 30px;text-indent: 2rem;">
          <p>
            预防性试验是电力设施运行和维护工作中一个重要环节，是保证电力设施安全运行的有效手段之一，对及时发现、诊断设备缺陷起到重要作用。为了发现运行中设备的隐患，预防发生事故或设备损坏，对设备进行的检查、试验或监测，也包括取油样或气样进行的试验。2015年，青岛地铁运营公司与青岛升平电气有限公司签订《青岛地铁3号线开闭所及预防性试验委外维保项目合同》，开展开闭所设备及外电源电缆维保、检修，正线、车辆段、控制中心变电设备高压预防性试验，电力安全工器具试验检测等工作，并取得2016、2017年度“优秀委外单位”的荣誉，得到青岛地铁的认可与好评。运维试验项目部自成立以来，与各界商家达成合作，除青岛地铁外，主要服务客户有：青特集团、万科企业股份有限公司、绿城房地产集团有限公司、通威集团、青岛银盛泰集团、青岛穗润食品有限公司及91049部队等，涉及物业、房地产、生产厂家、光伏发电站、部队变配电等各类行业用电试验，在运维试验领域中取得了各界合作伙伴的信任与支持。
          </p>
        </div>
      </div>
    </lay-col>
    <lay-col sm="16" sm-offset="4" >
      <SignBoard title="理念与文化" sub-title="ideas and culture" ></SignBoard>
      <img class="img"  src="images/wenhua.png" alt="" style="width: 100%;height: auto;margin-bottom: 3px">
    </lay-col>
  </lay-row>
  <lay-row v-show="intrActivePage == 1">
    <lay-col sm="16" sm-offset="4" >
      <lay-row>
        <div style="height: 20px"></div>
      </lay-row>
      <lay-row >
        <lay-col :sm="isXl ? 12 : 24">
          <img src="images/zt.png" style="width: 100%;height: auto" alt="">
        </lay-col>
        <lay-col :sm="isXl ? 12 : 24" >
          <h3 style="font-size: 26px;height: 60px;line-height: 60px;text-align: center;margin-top: 20px;letter-spacing: 1px;" >青岛中通电力设备有限公司</h3>
          <p style="font-size: 21px;color: #545454;text-indent: 2rem;padding: 0 30px;margin-top: 30px;line-height: 40px;" >
            青岛中通电力设备有限公司位于青岛莱西市姜山工业园，青岛市2012年重点建设项目，是专业从事电力变压器、箱式变电站及其他输变电设备研发、生产、销售的现代化企业。
          </p>
        </lay-col>
      </lay-row>
      <lay-row>
        <div style="height: 20px"></div>
      </lay-row>
      <lay-row>
        <lay-col :sm="isXl ? 12 : 24" >
          <img src="images/hh.png?v=20231204" style="width: 100%;height: auto" alt="">
        </lay-col>
        <lay-col  :sm="isXl ? 12 : 24">
          <h3 style="font-size: 26px;height: 60px;line-height: 60px;text-align: center;margin-top: 20px;letter-spacing: 1px;">青岛鸿海电工科技有限公司</h3>
          <p style="font-size: 21px;color: #545454;text-indent: 2rem;padding: 0 30px;margin-top: 30px;line-height: 40px;">
            青岛鸿海电工科技有限公司主要从事送变电工程勘察、设计、咨询、监理服务。设计技术水平及能力满足国家及地方行业设计标准。
          </p>
        </lay-col>

      </lay-row>

    </lay-col>

  </lay-row>
  <lay-row v-show="intrActivePage == 3">
    <lay-col sm="14" sm-offset="5">
      <lay-row>
        <div style="height: 20px"></div>
      </lay-row>
      <lay-row style="text-align: center">
        <lay-col sm="24" >
          <img src="images/000001.png" class="estate-img" alt="">
          <img src="images/tuzhi.png" class="estate-img" alt="">
          <img src="images/chanye05.png" class="estate-img" alt="">
          <img src="images/shigong.png" class="estate-img" alt="">
          <img src="images/000006.jpeg" class="estate-img" alt="">
          <img src="images/000003.png" class="estate-img" alt="">
          <img src="images/000004.png" class="estate-img" alt="">
          <img src="images/tuoguan.png" class="estate-img" alt="">
        </lay-col>
      </lay-row>
      <lay-row>
        <div style="height: 15px"></div>
      </lay-row>
    </lay-col>
  </lay-row>
  <lay-row v-show="intrActivePage == 2">
    <lay-col sm="16" sm-offset="4">
      <SignBoard title="高压柜" sub-title="high-voltage"></SignBoard>
    </lay-col>
    <lay-col sm="24"><div style="height: 15px"></div></lay-col>
    <lay-col sm="16" sm-offset="4" style="text-align: center" >
      <lay-row :space="isSm ? 10 : 0">
<!--        <lay-col sm="8">-->
<!--          <img src="images/gao1.png" class="product-img" alt="">-->
<!--          <h5 class="product-title">HXGN15-12环网柜</h5>-->
<!--        </lay-col>-->
<!--        <lay-col sm="8">-->
<!--          <img src="images/gao2.png" class="product-img" alt="">-->
<!--          <h5 class="product-title">XGN2-12高压柜</h5>-->
<!--        </lay-col>-->
<!--        <lay-col sm="8">-->
<!--          <img src="images/gao3.png" class="product-img" alt="">-->
<!--          <h5 class="product-title">XGN2-12高压柜</h5>-->
<!--        </lay-col>-->
<!--        <lay-col sm="24"><div style="height: 10px"></div></lay-col>-->
<!--        <lay-col sm="8">-->
<!--          <img src="images/gao4.png" class="product-img" alt="">-->
<!--          <h5 class="product-title">XGN2-12高压柜</h5>-->
<!--        </lay-col>-->
        <lay-col sm="8">
          <img src="images/KYN61-405.png" class="product-img" alt="" >
<!--          <h5 class="product-title">JYN1-40.5高压柜</h5>-->
          <h5 class="product-title">KYN61-40.5高压柜</h5>
        </lay-col>
        <lay-col sm="8">
          <img src="images/KYN28A-12.png" class="product-img" alt="">
          <h5 class="product-title">KYN28A-12高压柜</h5>
        </lay-col>
      </lay-row>
    </lay-col>
    <lay-col sm="16" sm-offset="4">
      <SignBoard title="低压柜" sub-title="low pressure tank"></SignBoard>
    </lay-col>
    <lay-col sm="24"><div style="height: 15px"></div></lay-col>
    <lay-col sm="16" sm-offset="4" style="text-align: center">
      <lay-row :space="isSm ? 10 : 0" >
        <lay-col sm="8">
          <img src="images/SLVA.png" class="product-img" alt="">
          <h5 class="product-title">SLVA低压柜</h5>

        </lay-col>
        <lay-col sm="8">
          <img src="images/GCK.png" class="product-img" alt="">
          <h5 class="product-title">GCK低压柜</h5>

        </lay-col>
        <lay-col sm="8">
          <img src="images/GGD.png" class="product-img" alt="">
          <h5 class="product-title">GGD低压柜</h5>

        </lay-col>
        <lay-col sm="24"><div style="height: 10px"></div></lay-col>
        <lay-col sm="8">
          <img src="images/GCS.png" class="product-img" alt="">
          <h5 class="product-title">GCS低压柜</h5>

        </lay-col>
        <lay-col sm="8">
          <img src="images/MNS.png" class="product-img" alt="">
          <h5 class="product-title">MNS低压柜</h5>

        </lay-col>
      </lay-row>
    </lay-col>
    <lay-col sm="16" sm-offset="4">
      <SignBoard title="母线槽" sub-title="generatrix"></SignBoard>
    </lay-col>
    <lay-col sm="24"><div style="height: 15px"></div></lay-col>
    <lay-col sm="16" sm-offset="4" style="text-align: center" >
      <lay-row :space="isSm ? 10 : 0">
        <lay-col sm="8">
          <img src="images/kq.png" class="product-img" alt="">
          <h5 class="product-title">空气式封闭母线槽</h5>

        </lay-col>
        <lay-col sm="8">
          <img src="images/mj.png" class="product-img" alt="">
          <h5 class="product-title">密集式封闭母线槽</h5>

        </lay-col>
        <lay-col sm="8">
          <img src="images/jz.png" class="product-img" alt="">
          <h5 class="product-title">浇筑型母线槽</h5>

        </lay-col>

      </lay-row>
    </lay-col>
    <lay-col sm="16" sm-offset="4">
      <SignBoard title="配电箱" sub-title="power distribution box"></SignBoard>
    </lay-col>
    <lay-col sm="24"><div style="height: 15px"></div></lay-col>
    <lay-col sm="16" sm-offset="4" style="text-align: center" >
      <lay-row :space="isSm ? 10 : 0">
        <lay-col sm="8">
          <img src="images/d1.png?v=20231122" class="product-img" alt="">
          <h5 class="product-title">配电箱</h5>

        </lay-col>
        <lay-col sm="8">
          <img src="images/d2.png?v=20231122" class="product-img" alt="">
          <h5 class="product-title">配电箱</h5>

        </lay-col>
        <lay-col sm="8">
          <img src="images/sdy.png" class="product-img" alt="">
          <h5 class="product-title">双电源箱</h5>

        </lay-col>
      </lay-row>
    </lay-col>
  </lay-row>
</template>

<script setup>
import PageNav from "@/components/utils/PageNav";
import SignBoard from "@/components/utils/SignBoard";
import {inject} from "vue";
import {useSize} from "@/assets/js/size";

const intrActivePage = inject("intrActivePage")
const changeIntrActivePage = inject("changeIntrActivePage")
const isXs = inject('isXs')
const isSm = inject('isSm')
const isMd = inject('isMd')
const isXl = inject('isXl')
const {width} = useSize()


</script>

<style scoped>
.intr{
  font-size: 17px;
}
.intr:hover,.active{
  color: #00b4ff;
}
.estate-img{
  width: 100%;
  height: auto;
  margin-bottom: 5px;
}
.product-img{
  width: 90%;
  height: auto
}
.product-title{
  height: 50px;
  line-height: 50px;
  font-size: 16px;
}


.intr:after{
  border-bottom: 3px solid #00b4ff;
  width: 0px;
  content: "";
  display: block;
  margin-top: -3px;
  transition: width 0.5s;
  -moz-transition: width 0.5s;
  -webkit-transition: width 0.5s;
  -o-transition: width 0.5s;
}
.intr.active:after,.intr:hover:after{
  width: 100%;
}
</style>