import {createApp} from 'vue'
import {createRouter,createWebHistory} from 'vue-router'
import App from './App.vue'
import Layui from '@layui/layui-vue'
import '@layui/layui-vue/lib/index.css'
import ContactUs from "@/components/ContactUs";
import HonorsView from "@/components/HonorsView";
import ProjectCase from "@/components/ProjectCase";
import IntrInfo from "@/components/IntrInfo";
import MainPage from "@/components/MainPage";



const routes = [
    { path: '/', component: MainPage},
    { path: '/intr', component: IntrInfo },
    { path: '/case', component: ProjectCase },
    { path: '/honor', component: HonorsView },
    { path: '/contact', component: ContactUs },
]

const router = createRouter({
    history: createWebHistory(),
    routes,

})



const app = createApp(App,{

})
app.config.silent = true;
app.directive('userNoSelect', (el) => {
    el.style.userSelect = 'none'
    el.style['moz-user-select'] = '-moz-none'
    el.style['-o-user-select'] = 'none'
    el.style['-khtml-user-select'] = 'none'
    el.style['-webkit-user-select'] = 'none'
    el.style['-ms-user-select'] = 'none'
})

app.use(router);

app.use(Layui).mount('#app')
